<template>
  <DoExamination>
    <template #nav>
      <div class="header fc" :class="{isPad: app.isPad}">
        <h2 class="b">错题巩固</h2>
        <span class="desc">练习</span>
        <p>针对您的错题为您选取了相同难度的巩固练习，加油！</p>
      </div>
    </template>
    <template #answerSheetBtn="answerSheetSlotProps">
      <div class="tc">
        <span class="sidebar-submit-btn" @click="answerSheetSlotProps.showSubmitPaperDialog('提交练习')">提交练习</span>
      </div>
    </template>
  </DoExamination>
</template>

<script>
import DoExamination from 'comps/doExamination.vue'

export default {
  components: {
    DoExamination
  },
  inject: ['app']
}
</script>

<style lang="scss" scoped>
.header {
  min-width: 768px;
  padding-top: 30px;
  padding-bottom: 28px;
  background: #FFFFFF;
  border-radius: 25px 25px 0px 0px;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  &.isPad {
    h2 {
      font-size: 24px;
    }
  }
  h2 {
    font-size: 28px;
    color: #333;
  }
  .desc {
    font-size: 20px;
    color: #999;
    margin-top: 14px;
  }
  p {
    margin-top: 25px;
    font-size: 18px;
    color: #555;
  }
}
.sidebar-submit-btn {
  display: inline-block;
  margin-top: 34px;
  width: 104px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background: #68B8E6;
  border-radius: 21px;
  color: white;
  cursor: pointer;
}
</style>